import { Component, ElementRef, Input, OnDestroy, OnInit, signal, ViewChild } from '@angular/core';
import { forkJoin, map, Observable, Subscription } from "rxjs";

import { LanguageService } from "../../../core/services/language.service";
import { Attachment } from "../../../core/models/webSocketMessage";
import { EventService } from "../../../core/services/event.service";
import { MultimediaService } from "../../../core/services/multimedia.service";
import { MessageDisplayFormat } from "../../../core/models/messageDisplayFormat";
import { HttpService } from "../../../core/services/http.service";

@Component({
  selector: 'app-related-content',
  templateUrl: './related-content.component.html',
  styleUrl: './related-content.component.scss'
})
export class RelatedContentComponent implements OnInit, OnDestroy {
    @Input() attachments: Attachment[] = [];
    @Input() author!: string;
    blobUrls: { [key: string]: string } = {};

    relatedContentTitle: string = 'Related Content:';

    translateSubscription!: Subscription;

    imageWidth = 240; // Image width + margin (300px + 20px margin)
    visibleCount = 4;
    currentIndex = 0;

    constructor(
        private language: LanguageService,
        private event: EventService,
        private multimedia: MultimediaService,
        private http: HttpService) { }

    async ngOnInit() {
        this.translateSubscription = this.language.selectedLanguage$.subscribe((selectedLanguage) => {
            const translate = this.language.getDesignTranslation(selectedLanguage.locale);
            this.relatedContentTitle = translate.typography.relatedContentTitle;
        });

        await this.loadBlobUrls();
    }

    backContent() {
        if (this.currentIndex > 0) {
            this.currentIndex--;
        }
    }

    nextContent() {
        if (this.currentIndex < this.maxOffset) {
            this.currentIndex++;
        }
    }

    get maxOffset() {
        return Math.max(this.attachments.length - this.visibleCount, 0);
    }

    get transform() {
        return `translateX(${-this.currentIndex * this.imageWidth}px)`;
    }

    // TODO CHANGE THIS
    loadBlobUrls() {
        this.attachments = this.attachments.map((attachment) => {
            attachment.url = attachment.url.replace("/get", "/cdn");
            if (attachment.url.endsWith("jpg") ||
                attachment.url.endsWith("png") ||
                attachment.url.endsWith("gif") ||
                attachment.url.endsWith("jpeg")) {
                attachment.type = "image";
            } else if (attachment.url.endsWith("pdf")) {
                attachment.type = "document";
            } else {
                attachment.type = "video";
            }

            return attachment;
        });
    }

    emitMediaType(mediaType: string, source: string) {
        this.multimedia.emitMediaData(mediaType, source);
        this.event.blurEfectEvent.emit(true);
    }

    ngOnDestroy() {
        if (this.translateSubscription) { this.translateSubscription.unsubscribe(); }
    }
}
