import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { map, Observable } from "rxjs";
import { HttpResponseModel } from "../models/http.model";

export interface HttpHeadersObject {
    [key: string]: string
}

@Injectable({
    providedIn: 'root'
})
export class HttpService {
    host: string | null = null;
    method: string = "GET";
    headers: HttpHeaders | undefined = undefined;
    content: Object | FormData | null = null;

    constructor(private http: HttpClient) {}

    getBlobUrl(url: string): Observable<string> {
        return this.http.get(url, {
            responseType: 'blob',
            headers: {
                'ngrok-skip-browser-warning': 'bilosta'
            }
        }).pipe(
            map(blob => URL.createObjectURL(blob))
        );
    }

    // setters
    setHost(host: string): HttpService {
        this.host = host;
        return this;
    }

    setMethod (method: string): HttpService  {
        this.method = method;
        return this;
    }

    setHeaders (headers: HttpHeaders | HttpHeadersObject): HttpService  {
        if (headers instanceof HttpHeaders) {
            this.headers = headers;
        } else {
            let httpHeaders = new HttpHeaders();
            Object.keys(headers).forEach(key => {
                httpHeaders = httpHeaders.set(key, headers[key]);
            });
            this.headers = httpHeaders;
        }

        return this;
    }

    setContent (content: Object | FormData): HttpService  {
        this.content = !(content instanceof FormData) ? JSON.stringify(content) : content;
        return this
    }

    // methods
    create<T> (): Observable<HttpResponseModel<T>> {
        let response: Observable<HttpResponse<T>>;
        switch (this.method) {
            case "GET":
                response = this.http.get<T>(this.host ?? "", { headers: this.headers, observe: 'response' });
                break;
            case "POST":
                response = this.http.post<T>(this.host ?? "", this.content, { headers: this.headers, observe: 'response' });
                break;
            case "PUT":
                response = this.http.put<T>(this.host ?? "", this.content, { headers: this.headers, observe: 'response' });
                break;
            case "DELETE":
                response = this.http.delete<T>(this.host ?? "", { headers: this.headers, observe: 'response' });
                break;
            default:
                throw new Error("unsupported method");
        }

        return response.pipe(
            map(res => ({
                status: res.status,
                body: res.body as T,
                message: res.statusText
            }))
        );
        /*if (this.method === "GET") {
            return this.http.get(this.host ?? "", {headers: this.headers, observe: 'response'});
        } else if (this.method === "POST") {
            return this.http.post(this.host ?? "", this.content, {headers: this.headers, observe: 'response'});
        }

        throw new Error("unsupported method");*/
    }
}
