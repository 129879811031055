import { RelatedQuestion } from "./relatedQuestion";

export interface ClientAttachment {
    type: string;
    url: string;
    name: string;
    extension: string;
}

export class Attachment {
    type: string;
    url: string;
    name: string;
    extension: string;

    constructor(type: string, url: string, name?: string) {
        this.type = type;
        this.url = url;
        this.name = name || this.getName();
        this.extension = this.getExtension();
    }

    private getName(): string {
        const segments = this.url.split('/');
        return segments.pop() || '';
    }

    private getExtension(): string {
        return this.name.split('.').pop()?.toUpperCase() || '';
    }
}

export interface WebSocketMessage {
    id?: string;
    type: string;
    content: string;
    language: string;
    token: string | null;
    mode?: string;
    audioAnswer?: string;
    attachments?: Attachment[];
    questionList?: RelatedQuestion[];
}

export interface WebSocketFeedback {
    type: string;
    feedback_id: string; //message_id
    feedback_status: boolean; //like or dislike
    feedback_message: string; // text of feedback
    feedback_reason: string; // one of the selected reasons
}
