import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, Subscription } from "rxjs";

import { WebSocketMessage } from "../../../core/models/webSocketMessage";
import { WebSocketService } from "../../../core/services/web-socket.service";
import { VisibilityService } from "../../../core/services/visibility.service";
import { MessageService } from "../../../core/services/message.service";
import { InteractionService } from "../../../core/services/interaction.service";
import { LanguageService } from "../../../core/services/language.service";
import { AudioService } from "../../../core/services/audio.service";
import { EventService } from "../../../core/services/event.service";
import { AnimationService } from "../../../core/services/animation.service";
import { MicrophoneService } from "../../../core/services/microphone.service";
import {ConfigService} from "../../../core/services/config.service";

@Component({
    selector: 'app-conversation',
    templateUrl: './conversation.component.html',
    styleUrl: './conversation.component.scss'
})
export class ConversationComponent implements OnInit, OnDestroy {
    talkStarted: boolean = false;
    isVisible: boolean = true;
    queueResponseMessage!: WebSocketMessage;

    visibilitySubscription!: Subscription;
    socketMessageSubscription!: Subscription
    audioLoadedSubscription!: Subscription;
    talkSubscription!: Subscription;

    constructor(
        private changeDetector: ChangeDetectorRef,
        private event: EventService,
        private config: ConfigService,
        private audio: AudioService,
        private socket: WebSocketService,
        private visibility: VisibilityService,
        private message: MessageService,
        private interaction: InteractionService,
        private language: LanguageService,
        private animation: AnimationService,
        private microphone: MicrophoneService) { }

    ngOnInit(): void {
        this.visibilitySubscription = this.visibility.getVisibility('avatar-conversation').subscribe(visible => {
            this.isVisible = visible;

            if (!this.isVisible) {
                this.visibility.showComponent('avatar-introduction');
            } else {
                this.visibility.hideComponent('avatar-introduction');
            }
        });

        this.talkSubscription = this.event.getStartedTalk().subscribe(isTalkStarted => {
            this.talkStarted = isTalkStarted;
        });

        this.socketMessageSubscription = this.socket.message
        .subscribe(async message => {
            // Detecting switching language from websocket message
            if (message.language && this.language.getSelectedLanguage().locale !== message.language) {
                this.language.setLanguage(message.language);
            }

            console.log(message);

            if (message.type === "transcription") {
                this.message.setMessage("client", message);
            } else if (message.type === "error") {
                this.message.setMessage("ai", message);
                this.animation.startDefaultAnimation();
                // If the message is an error and call is started, then we need to start mic again
                if (this.talkStarted && !this.microphone.getMicrophoneTalkStatus()) {
                    this.microphone.setMicrophoneTalkStatus(false);
                    await this.microphone.onMicrophoneClick(this.changeDetector, true);
                }
            } else if (message.type === "audio") {
                this.setQueueMessage(message);

                if (message.audioAnswer) {
                    if (this.microphone.getRobotTalkingStatus()) {
                        this.audio.setupAudio(message.audioAnswer);
                        this.audio.playAudio();
                    } else {
                        this.message.setMessage("ai", message);
                        this.animation.startDefaultAnimation();
                    }
                }
            } else if (message.type === "text") {
                this.message.setMessage("ai", message);
                this.animation.startDefaultAnimation();
            } else if (message.type === "related_questions") {
                if (message.questionList) {
                    console.log(message.questionList);
                    this.message.setMessageRelatedQuestion(message.questionList);
                } else {
                    this.message.setMessageRelatedQuestion([]);
                }
            }
        });

        this.audioLoadedSubscription = this.audio.onAudioLoaded.subscribe((loaded)=> {
            if (loaded && this.getQueueMessage() !== undefined) { this.message.setMessage("ai", this.getQueueMessage()); }
        });

        combineLatest([this.audio.isAudioPlaying, this.visibility.getVisibility('avatar-loader'),]).subscribe(([
            isAudioPlaying, isLoadingShown]) => {
            this.interaction.setInteractionMode(!(isAudioPlaying.isPlaying || isLoadingShown));
            //this.changeDetector.detectChanges();
        });

        this.event.scrollToBottomEvent.emit();
    }

    // getters
    getQueueMessage (): WebSocketMessage {
        return this.queueResponseMessage;
    }

    // setters
    setQueueMessage (message: WebSocketMessage) {
        this.queueResponseMessage = message;
    }

    ngOnDestroy () {
        if (this.visibilitySubscription) { this.visibilitySubscription.unsubscribe(); }
        if (this.socketMessageSubscription) { this.socketMessageSubscription.unsubscribe(); }
        if (this.audioLoadedSubscription) { this.audioLoadedSubscription.unsubscribe(); }
        if (this.talkSubscription) { this.talkSubscription.unsubscribe(); }
    }
}
